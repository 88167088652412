import axios from 'axios';
import router from '../router'
let baseURL = process.env.NODE_ENV == 'production' ? '' : '/api'
// 创建基础地址
const service = axios.create({
    baseURL
})

// 请求拦截
service.interceptors.request.use(config => {
    // console.log(config); // config是一个包含了所有请求信息的对象 在这里可以修改config对象 修改之后需要返回config对象 请求才会正常进行
    config.headers.token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    return config
}, err => {
    // throw new Error(err)
    Promise.reject(err)
})

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data.code == 401) {
                router.push('login');
            }
            return response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
        return Promise.reject(error);
    }
);
export default service;

