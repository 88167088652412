import axios from "./request";

/**
 * 船舶气体检测服务条件分页查询
 */
export const getShopGasMonitorList = (data) => {
    return axios.post(
        "/api/v1/gasMonitor/page?pageNum=" +
        data.pageNum +
        "&pageSize=" +
        data.pageSize,
        data.gasMonitorDTO ? data.gasMonitorDTO : {}
    );
};

/**
 * 船舶信息表服务接口
 */
export const getShopInfoList = (data) => {
    return axios.post("/api/v1/shipInfo/page?pageNum=" +
        data.pageNum +
        "&pageSize=" +
        data.pageSize,
        data.gasMonitorDTO ? data.gasMonitorDTO : {}
    );
};

/**
 * 监测气体阈值服务接口
 */
export const getGasInfoList = (data) => {
    return axios.post(
        "/api/v1/gasThreshold/page?pageNum=" +
        data.pageNum +
        "&pageSize=" +
        data.pageSize,
        data.gasThresholdDTO ? data.gasThresholdDTO : {}
    );
};

/**
 * 监测气体阈值服务接口
 */
export const exportGasInfoList = (data) => {
    return axios.post(
        "/api/v1/gasMonitor/export?pageNum=" +
        data.pageNum +
        "&pageSize=" +
        data.pageSize,
        data.gasThresholdDTO ? data.gasThresholdDTO : {}
    );
};

export const addGasInfo = (data) => {
    return axios.post("/api/v1/gasThreshold/add", data);
};

export const deleteGasInfo = (data) => {
    return axios.post("/api/v1/gasThreshold/deleteByIds", data);
};

export const updateGasInfo = (data) => {
    return axios.put("/api/v1/gasThreshold/update", data);
};

/**
 * 船舶通行图片和视频列表服务接口
 */
export const getShopVideoInfoList = (data) => {
    return axios.post(
        "/api/v1/syncMmsiData/page?pageNum=" +
        data.pageNum +
        "&pageSize=" +
        data.pageSize,
        data.mmsiDataPageDTO ? data.mmsiDataPageDTO : {}
    );
};

export const getVideoDetailInfo = (data) => {
    return axios.post(
        "/api/v1/syncMmsiData/selectPicAndVideoByShipAndBatchNo?mmsi=" +
        data.mmsi +
        "&batchNo=" +
        data.batchNo
    );
};

/**
 * 预警推送信息记录表服务接口
 */
export const getWarningInfoList = (data) => {
    return axios.post(
        "/api/v1/warnPushRecord/page?pageNum=" +
        data.pageNum +
        "&pageSize=" +
        data.pageSize,
        data.warnPushRecordDTO ? data.warnPushRecordDTO : {}
    );
};
/**
 * 用户表服务接口
 */
export const getUserInfoList = (data) => {
    return axios.post(
        "/api/v1/userInfo/page?pageNum=" +
        data.pageNum +
        "&pageSize=" +
        data.pageSize,
        data.pageParamDTO ? data.pageParamDTO : {}
    );
};

export const addUserInfo = (data) => {
    return axios.post("/api/v1/userInfo/add", data);
};

export const deleteUserInfo = (data) => {
    return axios.post("/api/v1/userInfo/deleteByIds", data);
};

export const updateUserInfo = (data) => {
    return axios.put("/api/v1/userInfo/update", data);
};

export const resetUserPassword = (data) => {
    return axios.put("/api/v1/userInfo/resetPassword", data);
};

/*******************首页接口*********************** */

/**
 * 获取当前天气数据
 */
export const getWeatherInfo = () => {
    return axios.get("/api/v1/index/getWeather");
};

export const getShipNum = () => {
    return axios.get("/api/v1/index/getShipNumByNowDate");
};

/**
 * 获取视频流
 */

export const getVideoUrlList = (data) => {
    return axios.get("/api/v1/index/getVideoMonitorUrl?type=" + data.type);
};

/**
 * 获取统计曲线
 */
export const getCurrentChartDataList = (data) => {
    return axios.get("/api/v1/index/getIndexLineByTime?type=" + data.type);
};

/**
 * 获取实时监测曲线
 */
export const getDateRangeChartDataList = () => {
    return axios.get("/api/v1/index/getLastedGagMonitor");
};

/**
 * 用户登录
 */
export const loginIn = (data) => {
    return axios.post("/api/v1/login/login", data);
};

export const loginOut = (data) => {
    return axios.post("/api/v1/login/loginOut?userName=" + data.userName);
};
